<!--
    Created by 王丽莎  on 2024/01/25.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：系统管理-商户信息
-->
<style lang="less">
#merchant-info {
  .merchant-detail {
    position: relative;
    padding: 0 @containerGap @containerGap;
    border-radius: @borderRadiusMid;
    overflow: hidden;
    background-color: @backColorStrong;
    .merchant-content {
      position: relative;
      transition: all @transitionTime25;
      .row-area {
        &+.row-area {
          margin-top: @containerGap * 2;
        }
        .info-table {
          display: flex;
          flex-wrap: wrap;
          font-size: 13px;
          box-sizing: border-box;
          line-height: 17px;
          .info-table-row {
            display: flex;
            align-content: center;
            padding: @containerGap * 0.5 0;
            .flex-grow(50%);
            .reset-pwd {
              margin-left: @containerGap * 4;
              height: auto;
            }
          }
        }
      }
      .account-card {
        padding: @containerGap;
        background-color: @backColorLight;
        border-radius: @borderRadiusMid;
        .has-gap {
          &+.has-gap {
            margin-top: @containerGap;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div id="merchant-info">
    <div class="merchant-detail">
      <div
        v-permission="'system:propertyCompany:mechant:query'"
        class="merchant-content"
      >
        <div
          v-for="(row, r) in infoItems"
          :key="'row-' + r"
          class="row-area"
        >
          <div class="h3 margin-primary-bottom">
            {{ row.title }}
          </div>
          <div
            v-if="row.title !== '资金账户'"
            class="info-table"
          >
            <div
              v-for="(item, i) in row.items"
              :key="i"
              class="info-table-row"
            >
              <yg-attribute-key-value
                :label="item.label"
                :label-width="120"
                :value="merchantInfo[item.key]"
                :can-hide-phone="item.hidePhone"
                :can-copy="item.canCopy"
              />
              <!--<yg-button-->
              <!--  v-if="item.key === 'username' && merchantInfo[item.key]"-->
              <!--  type="text"-->
              <!--  class="reset-pwd"-->
              <!--  @click="_clickResetPwd(item)"-->
              <!--&gt;-->
              <!--  重置登录密码-->
              <!--</yg-button>-->
            </div>
          </div>
          <div
            v-else
            class="table-content"
          >
            <div class="account-card">
              <yg-card
                v-for="(item, index) in tableColumns"
                :key="index"
                :title="item.title"
                class="has-gap"
              >
                <div slot="content">
                  <yg-table
                    :columns="item.items"
                    :data="tableData"
                    :max-height="9999"
                    class="table-box"
                  />
                </div>
              </yg-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--弹窗：提交(新增/编辑)-->
    <v-submit
      v-if="!!dialog.submitModel"
      :model="dialog.submitModel"
      @on-close="dialog.submitModel = null"
      @on-sure="dialog.submitModel = null"
    />
  </div>
</template>

<script>
import { MerchantInfoDataModel } from './model/MerchantInfoDataModel';
import ColumnMixin from '@/pages/100__systemManager/merchantInfo/mixin/tableColumn';
import vSubmit from './view/submit.vue';
import UserApiSet from '@/request/api/UserApiSet';
import { mapActions } from 'vuex';

export default {
  components: { vSubmit },
  mixins: [ColumnMixin],
  data () {
    return {
      // 基础信息数据
      infoItems: [
        {
          title: '登录账号设置',
          items: [
            { label: '登录账号：', key: 'username', canCopy: true }
          ]
        }
      ],
      baseInfo: {
        20: [
          { label: '所属平台：', key: 'platformName' },
          { label: '服务商名称：', key: 'providerName' },
          { label: '联系人：', key: 'contact' },
          { label: '联系人手机号：', key: 'mobile', canCopy: true, hidePhone: true },
          { label: '服务商主体类型：', key: 'principalTypeName' }
        ],
        30: [
          { label: '所属平台：', key: 'platformName' },
          { label: '所属服务商：', key: 'serviceProviderName' },
          { label: '设备商名称：', key: 'deviceProviderName' },
          { label: '联系人：', key: 'contact' },
          { label: '联系人手机号：', key: 'mobile', canCopy: true, hidePhone: true },
          { label: '服务商主体类型：', key: 'principalTypeName' }
        ],
        40: [
          { label: '所属平台：', key: 'platformName' },
          { label: '所属服务商：', key: 'serviceProviderName' },
          { label: '物业公司名称：', key: 'propertyCompanyName' },
          { label: '联系人：', key: 'contact' },
          { label: '联系人手机号：', key: 'mobile', canCopy: true, hidePhone: true },
          { label: '服务商主体类型：', key: 'principalTypeName' }
        ]
      },
      merchantInfo: {},
      // 弹窗
      dialog: {
        submitModel: null
      },
      isOnLine: false,
      tableData: [],
      userType: null,
      principalTypeList: []
    };
  },
  computed: {
    tableColumns () {
      if (this.$storage.user.userType === this.$config.systemUserType.service) {
        // 服务商
        return this.fws_tableColumns;
      } else if (this.$storage.user.userType === this.$config.systemUserType.property) {
        // 物业公司
        return this.wygs_tableColumns;
      }
      return [];
    }
  },
  created () {
    this.$ygLoading.show();
    const userType = this.$storage.user.userType;
    if (userType && userType !== this.$config.systemUserType.admin) {
      let title = '';
      if (userType === this.$config.systemUserType.service) {
        title = '服务商';
      } else if (userType === this.$config.systemUserType.device) {
        title = '设备商';
      } else if (userType === this.$config.systemUserType.property) {
        title = '物业公司';
      }
      this.infoItems.unshift({
        title: title + '基础信息',
        items: this.baseInfo[userType]
      });
    }
    // 只有服务商和物业公司有资金账户这一项
    if (userType === this.$config.systemUserType.service || userType === this.$config.systemUserType.property) {
      this.infoItems.push({
        title: '资金账户'
      });
    }
    Promise.all([this.getPrincipalTypeList()]).then(res => {
      this.merchantInfo = this.$storage.merchantInfo;
      this.merchantInfo.username = this.$storage.user.username;
      this.merchantInfo.principalType = this.merchantInfo.principalType + '';
      this.merchantInfo.principalTypeList = res[0];
      this.merchantInfo = MerchantInfoDataModel.initModelWithData(this.merchantInfo);
      this.$ygLoading.close();
    });
    this.requestDetail();
  },
  methods: {
    ...mapActions(['getPrincipalTypeList']),
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    // 点击重置登录密码
    _clickResetPwd (data) {
      this.dialog.submitModel = MerchantInfoDataModel.initModelWithData(data);
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    // 获取详情
    requestDetail () {
      // 个人信息接口
      const api = UserApiSet.personalInfo;
      api.params = {};
      /**
       * userType
       * 20: 服务商 serviceProvider
       * 30: 设备商 deviceProvider
       * 40: 物业公司 propertyCompany
       */
      this.$http(api).then(res => {
        const { adminUser, merchantCodes, settlementAccount } = res.data;
        if (adminUser.userType === this.$config.systemUserType.service) {
          this.tableData = MerchantInfoDataModel.initListWithDataList(merchantCodes);
        } else if (adminUser.userType === this.$config.systemUserType.property) {
          if (settlementAccount) {
            this.tableData.push(MerchantInfoDataModel.initModelWithData(settlementAccount));
          }
        }
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
