
export default {
  data () {
    return {
      fws_tableColumns: [
        {
          title: '线上收款账户',
          items: [
            BaseTableColumn.initData('index'),
            BaseTableColumn.initData('payChannelTypeName', '支付商户渠道').setSpan({}),
            BaseTableColumn.initData('merchantCode', '商户号').setSpan({ canCopy: true })
          ],
          tips: '账户用途：收取消费者支付的资金；'
        }
      ],
      wygs_tableColumns: [
        {
          title: '提现打款账户',
          items: [
            BaseTableColumn.initData('index'),
            BaseTableColumn.initData('cardNo', '银行卡号').setSpan({ canCopy: true }),
            BaseTableColumn.initData('accountTypeName', '账户类型').setSpan({}),
            BaseTableColumn.initData('accountName', '开户人名称').setSpan({}),
            // BaseTableColumn.initData('accountsBank', '开户行').setSpan({}),
            BaseTableColumn.initData('h5Area', '开户所在地').setSpan({}),
            BaseTableColumn.initData('bankName', '开户银行全称').setSpan({})
          ],
          tips: '账户用途：用于服务商提现计算打款的账户；'
        }
      ]
    };
  }
};
