<!--
    Created by 王丽莎  on 2024/01/25.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：系统管理-商户信息-重置登录密码
-->
<style lang="less">

</style>

<template>
  <yg-modal
    width="600"
    title="重置密码"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledMsg"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      style="min-height: 300px"
    >
      <Form>
        <FormItem
          v-for="(item, index) in formItems"
          :key="index"
          :label="item.label"
          :label-width="item.labelWidth"
          :required="item.isRequired"
        >
          <Input
            v-if="item.tagName === 'Input'"
            v-model="model[item.key]"
            type="text"
            :maxlength="item.maxLength"
            :placeholder="'请输入' + item.label"
            :disabled="item.disabled"
            @input="checkSubmit"
          />
          <div v-if="item.tagName === 'Div'">
            {{ model[item.key] || '-' }}
          </div>
        </FormItem>
      </Form>
    </div>
  </yg-modal>
</template>

<script>
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      buttonDisabled: !this.model.id,
      buttonDisabledMsg: '',
      formItems: [
        {
          label: '登录账号',
          labelWidth: 100,
          key: 'account',
          tagName: 'Div',
          isRequired: false
        },
        {
          label: '登录密码',
          labelWidth: 100,
          key: 'pwd',
          tagName: 'Input',
          disabled: false,
          isRequired: true,
          maxLength: '6'
        },
        {
          label: '确认密码',
          labelWidth: 100,
          key: 'confirmPwd',
          tagName: 'Input',
          disabled: false,
          isRequired: true,
          maxLength: '6'
        }
      ]
    };
  },
  created () {
    console.log(this.model);
  },
  methods: {
    onSure () {
      const res = this.model.checkValidSubmit();
      if (!res.success) {
        this.$Message.warning(res.msg);
      } else {
        this.$emit('on-sure', res.params);
      }
    },
    onClose () {
      this.$emit('on-close');
    },
    checkSubmit () {
      const item = this.formItems.find(v => v.isRequired && !this.model[v.key]);
      this.buttonDisabled = !!item;
      this.buttonDisabledMsg = item ? (item.label + '不可为空') : '';
    }
  }
};
</script>
