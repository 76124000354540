import BaseModel from '@/libs/base/dataModel/BaseModel';
import { isAlphanumeric } from '@/libs/regularUtils';

export class MerchantInfoDataModel extends BaseModel {
  constructor () {
    super();
    // 证件号码
    this.idCard = '';
    // 证件类型
    this.idCardType = '';
    // 开户行--支行
    this.branchBank = '';
    // 银行账号
    this.bankNo = '';
    // 新登录密码
    this.pwd = '';
    // 确认登录密码
    this.confirmPwd = '';
    // 登录账号
    this.account = '';
    // 手机号
    this.tel = '';
    // 账户类型
    this.accountType = '';
    this.accountTypeName = '';
    // 支付商户渠道
    this.payChannel = '';
    // 商户号
    this.merchantNo = '';
    // 银行卡号
    this.cardNo = '';
    // 账户类型
    this.type = '';
    // 开户人名称
    this.accountHolder = '';
    // 开户行
    this.openingBank = '';
    // 开户所在地
    this.openingArea = '';
    // 开户银行全称
    this.openingBankName = '';
    // 联系人
    this.contact = '';
    // 服务商名称
    this.providerName = '';
    // 所属平台
    this.platformName = '智能充电管理平台';
    // 联系电话
    this.mobile = '';
    // 服务商主体类型
    this.principalType = '';
    this.principalTypeName = '';
    // 所属服务商
    this.deviceProviderName = '';
    // 登录账号
    this.username = '';
    // 所属服务商
    this.serviceProviderName = '';
    // 物业公司名称
    this.propertyCompanyName = '';
    // 开户人名称
    this.accountName = '';
    // 开户行
    this.accountsBank = '';
    // 开户银行全称
    this.bankName = '';
    // 市
    this.city = '';
    this.cityName = '';
    // 区
    this.district = '';
    this.districtName = '';
    // 省
    this.province = '';
    this.provinceName = '';
    // 物业公司ID/商户号Id
    this.id = '';
    this.propertyCompanyId = '';
    this.status = '';
    // 线上收款账户：
    // 支付渠道类型（1：微信, 2：银联, 3：通联）
    this.payChannelType = '';
    this.payChannelTypeName = '';
    // 服务商ID
    this.serviceProviderId = '';
    // 商户号
    this.merchantCode = '';
    // 前端展示
    this.h5Area = '';
  }

  initData (resData) {
    super.initData(resData);
    console.log(resData);
    this.principalTypeName = ((resData.principalTypeList || []).find(v => v.value === resData.principalType) || {}).label;
    this.h5Area = (resData.provinceName || '') + (resData.cityName || '') + (resData.districtName || '');
    this.accountTypeName = resData.accountName === 1 ? '对公账户' : '对私账户';
    return this;
  }

  static createFakeDataOnlineList (len = 3) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const payChannelDesc = ['微信支付', '银联支付', '通联支付'];
      const dic = {
        id: BaseModel.getFakeNumber(99999999, 0),
        accountType: '线上收款账户',
        payChannel: payChannelDesc[i % 3],
        merchantNo: 'JLL850495' + i
      };
      list.push(dic);
    }
    return list;
  }

  static createFakeDataList (len = 1) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        id: BaseModel.getFakeNumber(99999999, 0),
        accountType: '提现打款账户',
        cardNo: '458750457349534975345' + i,
        type: '对公账户',
        accountHolder: '上海吾行信息技术有限公司',
        openingBank: '招商银行',
        openingArea: '上海市普陀区',
        openingBankName: '招商银行上海分行营业部'
      };
      list.push(dic);
    }
    return list;
  }

  static createFakeData () {
    return {
      idCard: '21315465732132100',
      idCardType: '身份证',
      branchBank: '招商银行',
      bankNo: '4003894502132465789',
      account: '14384837492',
      tel: '13797400225'
    };
  }

  /**
   * 数据具体校验-重置登录密码
   * @returns {*}
   */
  checkValidSubmit () {
    // 专项校验
    if (!isAlphanumeric(this.pwd)) {
      return BaseModel.getValidRes(false, '请输入6~6位密码，必须为数字字母组合');
    } else if (!isAlphanumeric(this.confirmPwd)) {
      return BaseModel.getValidRes(false, '请输入6~6位密码，必须为数字字母组合');
    }

    return BaseModel.getValidRes(true, '', {
      ownerId: this.id,
      account: this.account,
      pwd: this.pwd,
      confirmPwd: this.confirmPwd
    });
  }
}
